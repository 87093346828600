<template>

  <section class="main_page">

    <div class="main_screen">
      <div class="main_screen_container">
      <v-lazy-image src="img/lines.svg" class="lines" alt=""/>
      <v-lazy-image src="img/main_banner.png" class="main_banner" alt=""/>
      <div class="header_text">Develop complete solutions for opening a gambling business</div>
      <div class="sub_header_text">We are the architects of success in the iGaming industry</div>
      <v-lazy-image src="img/main_button.svg" @click="mail()" class="main_button" alt=""/>
      </div>
    </div>

    <div class="about_us" id="about_us">
      <div class="about_us_container">
      <v-lazy-image src="img/about_us_img.jpg" class="about_us_img" alt=""/>
      <div class="about_us_row">
        <div class="about_us_header">About Us</div>
        <div class="ubout_us_line"></div>
        <div class="about_us_text">
          <b>Complete Technologies is a like-minded iGaming company.</b> We have managed to try ourselves in B2C niches, so we have domain expertise in the gambling market.
          It is never boring with us, but nevertheless,
          it is never troublesome because we have been building up a coherent business process for years. This helps us to give the client the best product we can.
        </div>
      </div>
      </div>
    </div>

    <div class="our_main_project" id="our_main_project">
      <div class="our_main_project_container">
      <div class="main_project_headline">Our Main Project</div>
      <div class="main_project_line"></div>
      <v-lazy-image src="img/main_product.png" class="main_product" alt=""/>
      <div class="main_project_text">
        This is an online casino with a Curacao license. In a year of its existence, we have successfully entered and established ourselves in new markets. Our clients are successful people who genuinely appreciate the excitement and new experiences. For them, we provide high-quality service and a variety of innovations. Games, live tables, sports betting — all kinds of entertainment.
      </div>
      <v-lazy-image src="img/license_desktop.svg" class="license_desktop" alt=""/>
        <v-lazy-image src="img/license_mobile.svg" class="license_mobile" alt=""/>
      <v-lazy-image src="img/main_product_button.svg" @click="site()" class="main_product_button" alt=""/>
      <v-lazy-image src="img/main_product_img.png" class="main_product_img" alt=""/>
      </div>
    </div>

    <div class="our_services" id="our_services">
      <div class="our_services_container">
      <div class="main_project_headline">Our Services</div>
      <div class="main_project_line"></div>
      <div class="serv_panel">
      <div class="navigation">
      <v-lazy-image src="img/arrow.svg" @click="slider_prew()" class="arrow" alt=""/>
      <v-lazy-image src="img/arrow.svg" @click="slider_next()" class="arrow ar_rotate" alt=""/>
      </div>
      <div class="cards_clip">
       <div class="cards_group s0" id="cards_group">
         <v-lazy-image src="img/serv/serv1.svg" class="card" alt=""/>
         <v-lazy-image src="img/serv/serv2.svg" class="card" alt=""/>
         <v-lazy-image src="img/serv/serv3.svg" class="card" alt=""/>
         <v-lazy-image src="img/serv/serv4.svg" class="card" alt=""/>
         <v-lazy-image src="img/serv/serv5.svg" class="card" alt=""/>
         <v-lazy-image src="img/serv/serv6.svg" class="card" alt=""/>
         <v-lazy-image src="img/serv/serv7.svg" class="card" alt=""/>
       </div>
      </div>
      </div>
      </div>
    </div>

    <div class="career" id="career">
      <div class="career_container">
      <v-lazy-image src="img/career_img.png" class="career_img" alt=""/>
      <div class="main_project_headline" style="left: 0">Career</div>
      <div class="main_project_line" style="left: 0"></div>
      <v-lazy-image src="img/career_vac.svg" class="career_vac" alt=""/>
        <v-lazy-image src="img/career_vac_mobile.svg" class="career_vac_mobile" alt=""/>
      <v-lazy-image @click="mail()" src="img/career_button.svg" class="career_button" alt=""/>
      </div>
    </div>

  </section>

</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage
  },
  methods: {
    slider_next() {
      if (document.getElementById("cards_group").classList.contains("s0")) {
        document.getElementById("cards_group").classList.remove("s0");
        document.getElementById("cards_group").classList.add("s1");
      } else
      if (document.getElementById("cards_group").classList.contains("s1")) {
        document.getElementById("cards_group").classList.remove("s1");
        document.getElementById("cards_group").classList.add("s2");
      } else
      if (document.getElementById("cards_group").classList.contains("s2")) {
        document.getElementById("cards_group").classList.remove("s2");
        document.getElementById("cards_group").classList.add("s3");
      } else
      if (document.getElementById("cards_group").classList.contains("s3")) {
        document.getElementById("cards_group").classList.remove("s3");
        document.getElementById("cards_group").classList.add("s4");
      } else
      if (document.getElementById("cards_group").classList.contains("s4")) {
        document.getElementById("cards_group").classList.remove("s4");
        document.getElementById("cards_group").classList.add("s5");
      } else
      if (document.getElementById("cards_group").classList.contains("s5")) {
        document.getElementById("cards_group").classList.remove("s5");
        document.getElementById("cards_group").classList.add("s6");
      } else
      if (document.getElementById("cards_group").classList.contains("s6")) {
        document.getElementById("cards_group").classList.remove("s6");
        document.getElementById("cards_group").classList.add("s0");
      }

    },
    slider_prew() {
      if (document.getElementById("cards_group").classList.contains("s1")) {
        document.getElementById("cards_group").classList.remove("s1");
        document.getElementById("cards_group").classList.add("s0");
      } else
      if (document.getElementById("cards_group").classList.contains("s2")) {
        document.getElementById("cards_group").classList.remove("s2");
        document.getElementById("cards_group").classList.add("s1");
      } else
      if (document.getElementById("cards_group").classList.contains("s3")) {
        document.getElementById("cards_group").classList.remove("s3");
        document.getElementById("cards_group").classList.add("s2");
      } else
      if (document.getElementById("cards_group").classList.contains("s4")) {
        document.getElementById("cards_group").classList.remove("s4");
        document.getElementById("cards_group").classList.add("s3");
      } else
      if (document.getElementById("cards_group").classList.contains("s5")) {
        document.getElementById("cards_group").classList.remove("s5");
        document.getElementById("cards_group").classList.add("s4");
      } else
      if (document.getElementById("cards_group").classList.contains("s6")) {
        document.getElementById("cards_group").classList.remove("s6");
        document.getElementById("cards_group").classList.add("s5");
      }

    },
    mail() {
      window.location.href = "mailto:info@comtechnv.com";
    },
    site() {
      window.open(
          'https://winspirit.com',
          '_blank' // <- This is what makes it open in a new window.
      );
    }
  }
}
</script>


<style lang="stylus">

.main_page
  position relative
  width 100%


.main_screen
  position relative
  top 0
  display: flex;
  flex-direction: column;
  width 100%
  height: 693px;
  background: radial-gradient(60% 116.2% at 50% 0%, #583099 0%, #27144E 100%);

.main_screen_container
  width 1160px
  left 50%
  transform translate(-50%)
  position relative
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 106px;

.header_text
  width: 828px;
  height: 144px;
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 150%;
  color: #F8F2FF;
  text-align left
  position relative
  top: 82px;

.sub_header_text
  width: 828px;
  height: 33px;
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  color: #F8F2FF;
  position relative
  text-align left
  top: 90px;

.main_button
  position: relative;
  top: 155px;
  left: 0;
  width: 294px;
  height: 64px;
  cursor pointer
  transition filter .5s linear

.main_button:hover
  filter brightness(1.5)


.main_banner
  position: absolute;
  width: 874px
  height: 475px;
  left: 285px;
  top: 29px;
  z-index 0

.lines
  position absolute
  top: -106px;
  left 0
  width: 1159px;
  height: 693px;
  z-index 0

.about_us
  position relative
  width: 100%;
  background: linear-gradient(180deg, #47267F 0%, #26134D 100%);


.about_us_container
  position relative
  left 50%
  transform translate(-50%)
  width: 1160px;
  display: flex;
  justify-content: space-between;

.about_us_img
  position: relative;
  left: 0;
  top: -37px;
  height: 365px;
  width auto


.bg_gradient
  position relative
  width 100%
  height: 365px;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(88, 47, 153, 0.65) 0%, rgba(88, 47, 153, 0) 100%);

.about_us_row
  position relative
  display flex
  top 0
  flex-direction: column;
  left: 0;

.about_us_header
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  display: flex;
  color: #F8F2FF;
  top: 31px;
  position: relative;


.ubout_us_line
  position relative
  top: 46px;
  background: #582F99;
  height 1px
  width 418px

.about_us_text
  position relative
  top 62px
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #CCC3D9;
  text-align left
  width: 370px;

b
  color: #ED0063;

.main_project
  position relative
  top 0
  width 100%

.main_project_headline
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  color: #F8F2FF;
  text-align left;
  top: 44px;
  position: relative;

.our_main_project
  position relative
  width 100%

.our_main_project_container
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 705px;
  left 50%
  transform translate(-50%)
  width: 1160px;

.main_project_line
  position relative
  top: 59px;
  background: #582F99;
  height 1px
  width 100%

.main_product
  position relative
  top 148px
  left 0
  width: 303px;
  height: 64px;

.main_project_text
  width: 560px;
  height: 144px;
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #CCC3D9;
  position relative
  text-align left
  top: 164px;

.license_desktop
  width: 496px;
  height: 90px;
  top: 204px;
  position: relative;

.main_product_button
  position: relative;
  top: 244px;
  cursor pointer
  transition filter .5s linear

.main_product_button:hover
  filter brightness(1.4)

.main_product_img
  position: absolute;
  left: 599px;
  top: 154px;

.our_services
  position relative
  width 100%
  height: 710px;
  background: linear-gradient(180deg, #26134C 0%, #1A0D34 100%);


.our_services_container
  position relative
  top 0
  left 50%
  transform translate(-50%)
  width: 1160px;


.navigation
  position: relative;
  display: flex;
  width: 102px;
  height: auto;
  justify-content: space-between;
  top: 62px



.arrow
  position relative
  top 50px
  left 0
  z-index 5
  border 1px solid
  border-color #6C6084
  padding 15px
  border-radius 100%
  cursor pointer
  transition all .5s ease-in-out
  transform-origin 50% 50%
  height 11px
  width 11px

.arrow:hover
  padding 20px
  border-color #ED0064

.ar_rotate
  transform rotate(180deg)

.cards_clip
  width 960px
  height 460px
  position relative
  overflow hidden
  top: 108px;
  left: 98px;

.cards_group
  display flex
  justify-content: space-between;
  width 2060px
  left: 0;
  position: relative;
  transition all .5s ease-in-out


.card
  width 260px
  height: fit-content;

.serv_panel
  display flex
  position relative

.s0
  left 0
.s1
  left -300px
.s2
  left -600px
.s3
  left -900px
.s4
    left -1200px
.s5
    left -1500px
.s6
    left -1800px

.career
  position: relative;
  top: 0;
  width: 100%;
  height: 666px;

.career_container
  position: relative;
  top: 0;
  left 50%
  transform translate(-50%)
  width: 1160px;




.career_vac
  position: relative;
  top: 110px;
  left: -178px;
  cursor pointer
  transition filter .5s linear

.career_button
  position: relative;
  top: 221px;
  left: -739px;
  cursor pointer
  transition filter .5s linear

.career_button:hover
  filter brightness(1.5)

.career_vac:hover
  filter brightness(1.5)

.career_img
  position: absolute;
  top: 166px;
  left: 600px;
  width: 558px;
  height: 449px;

.license_mobile
  display none

.career_vac_mobile
  display none


@media (max-width: 1175px)
  .main_screen_container
    width 360px
  .header_text
    font-size 21px
    width: 314px;
    top: -27px;
    left: 16px;
  .lines
    width 100%
  .sub_header_text
    width: 240px;
    left: 16px;
    height: 33px;
    font-family: 'Sora', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #f8f2ff;
    position: relative;
    text-align: left;
    top: -68px;
  .main_button
     position: relative;
     top: 33px;
     left: 16px;
  .main_banner
    width: auto;
    height: 183px;
    left: 12px;
    top: 43px;
  .about_us_header
    font-size 21px
  .main_screen
    height: 395px;
    overflow hidden
  .about_us_container
    width 360px
    justify-content: space-between;
    flex-direction: column-reverse;
  .about_us_img
    left: 16px;
    top: -162px;
    width: 328.02px;
    height: 181.41px;
  .ubout_us_line
    top: 12px;
    height: 1px;
    width: 320px;
    left: 20px;
  .about_us_header
    top: 4px;
    left: 17px;

  .about_us_text
    top: 222px;
    width: 320px;
    left: 16px;
    font-size 14px
  .our_main_project_container
    width 360px
  .main_project_headline
    font-size 21px
    top: 67px;
    left: 16px
  .main_project_line {
    top: 76px;
    height: 1px;
    width: 320px;
    left: 20px;
  }
  .main_product_img
    width: 327px;
    height: 327px;
    left: 16px;
    top: 106px;
  .main_product
    position: relative;
    top: 403px;
    left: 16px;
  .main_project_text
    font-size 14px
    top: 419px;
    left: 17px
    width 320px
  .license_desktop
    display none
  .license_mobile
    display block
    position absolute
    top: 742px;
    left: 16px;
    width: 329px;
    height: 166px;
  .main_product_button
    top: 710px;
    left: 16px;
  .our_services_container
    width 360px
    top: -48px;
  .our_main_project_container
    height: 988px;
  .navigation
   display none
  .cards_clip
    top: 98px;
    left: 0;
    width 100%
    overflow: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 506px;
  .cards_clip::-webkit-scrollbar {
    display: none;
  }
  .cards_group
    width: 1920px;
    left: 19px;
    top: 10px;
  .career_container
    width 320px
  .our_services
    height 581px
  .main_project_line
    top: 85px;
    width: 320px;
    left: 20px;
  .career_img
    width: 327px;
    height: 263px;
    left: -5px;
    top: 127px;
  .career_vac
    display none
  .career_vac_mobile
    display block
    width: 328px;
    height: 179px;
    position absolute
    top: 398px;
    left: -4px;
  .career_button
    left: -43px;
    top: 570px;
  .main_screen
    height 480px
  .about_us_container
    top -80px
  .our_main_project
    top -70px
  .career
    height 730px
  .footer_container
    top -50px!important
  .footer
    height 420px!important
  












</style>
