<template>

  <section class="terms">
    <div class="terms_container">
      <p class="c1"><span class="c3">PRIVACY POLICY</span></p>
      <p class="c0"><span class="c2">We, Complete Technologies N.V., a company incorporated under the laws of Curacao, with the registered office at: Heelsumstraat 51, E-Commerce Park, Curacao, Willemstad, &nbsp;PO Box 422, ZIP Code 4797, registration number 133283 and from time to time, its subsidiaries and partners (together the &ldquo;Group&rdquo;) and each being &ldquo;member of the Group&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; for the purposes of this Privacy Policy are &quot;data controller&quot; of your personal information and &ldquo;processor&rdquo; of certain personal information, as described in more details in this Privacy Policy, recognize our responsibilities in relation to the collection, holding, processing, use and/or transfer of personal data under the applicable data protection laws. </span>
      </p>
      <p class="c0"><span class="c2">Personal data will be collected only for lawful and relevant purposes and all practicable steps will be taken to ensure that personal data held by us is accurate. We will use your personal data which we may from time to time collect in accordance with this Privacy Policy.</span>
      </p>
      <p class="c0"><span class="c2">We regularly review this Privacy Policy and may from time to time revise it or add specific instructions, policies and terms. Where any changes to this Privacy Policy are material, we will notify you accordingly.</span>
      </p>
      <p class="c0"><span class="c3">Legal basis</span></p>
      <p class="c0"><span class="c2">We need to collect certain types of information for compliance with legal requirements relating to our anti-fraud/anti-money laundering (AML) and know your customer (KYC) obligations under the applicable legal acts on protection of personal data. If this information is not provided we cannot agree to provide a service to you. Your personal information may also be processed if it is necessary on reasonable request by a law enforcement or regulatory authority, body or agency or in the defence of legal claims. We will not delete personal information if relevant to an investigation or a dispute. It will continue to be stored until those issues are fully resolved.</span>
      </p>
      <p class="c0"><span class="c3">Data collocation and storage</span></p>
      <p class="c0"><span class="c2">This Privacy Policy describes how we use your personal information when you use our website(s) or your business supplies services or products to us or we provide services to your business.</span>
      </p>
      <p class="c0"><span class="c2">We will collect, store and use your personal information to allow access to this website, register our customers, and provide our services and for the purposes set out in more detail below.</span>
      </p>
      <p class="c0"><span class="c3">What information we collect</span></p>
      <ul class="c7 lst-kix_list_3-0 start">
        <li class="c5 li-bullet-0"><span class="c2">Basic Personal Data: name, surname, job title etc.</span></li>
        <li class="c5 li-bullet-0"><span class="c2">Identification information and other background verification data: (your or your representative&rsquo;s, ultimate beneficiary owners) &ndash; name, surname, personal identity code, date of birth, address, nationality, gender, passport or ID card copy, evidence of beneficial ownership or the source of funds, number of shares held, voting rights or share capital part, title.</span>
        </li>
        <li class="c5 li-bullet-0"><span class="c2">Transaction data: beneficiary details, date, time, amount and currency which was used, name/IP address of sender and receiver, accounts, amount of transactions, income, location, etc.</span>
        </li>
        <li class="c5 li-bullet-0"><span class="c2">Information related to legal requirements: data resulting from enquiries made by the authorities, data that enables us to perform anti-money laundering requirements and ensure the compliance with international sanctions, including the purpose of the business relationship and whether you are a politically exposed person and other data that is required to be processed by us in order to comply with the legal obligation to &ldquo;know your client&rdquo;.</span>
        </li>
        <li class="c5 li-bullet-0"><span class="c2">Contact Data: registered/actual place of residence, phone number, e&ndash;mail address etc.</span>
        </li>
      </ul>
      <p class="c0"><span class="c3">Purposes and legal basis for Personal Data processing</span></p>
      <p class="c0"><span class="c2">We collect personal data for the purposes listed below:</span></p>
      <ul class="c7 lst-kix_list_4-0 start">
        <li class="c6 c12 li-bullet-1"><span class="c2">Conclusion of the contract or for performance of measures at your request prior to the conclusion of the contract (to identify and verify the clients).</span>
        </li>
      </ul>
      <p class="c6"><span class="c2">We may process your Basic Personal Data, Identification and other background verification Data, Contact Information and other Personal Data (in order to identify the possibility of providing services).</span>
      </p>
      <p class="c6"><span class="c2">Legal basis: concluding a contract with you, to fulfilling our legitimate interests and/or fulfilling the legal obligations applicable to us.</span>
      </p>
      <ul class="c7 lst-kix_list_4-0">
        <li class="c6 c12 li-bullet-1"><span class="c2">Fulfilment of a contract concluded with you.</span></li>
      </ul>
      <p class="c6"><span class="c2">For this purpose, we may process your Basic Personal Data, Identification and other background verification Data, Transaction Data, Information which is related to legal requirements, Contact Information and other Personal Data provided to us by or on behalf of you or generated by us in the course of providing services.</span>
      </p>
      <p class="c6"><span class="c2">Legal basis: performance of a contract signed with you, fulfilling our or third parties&rsquo; legitimate interests and/or compliance with legal obligations applicable to us.</span>
      </p>
      <ul class="c7 lst-kix_list_4-0">
        <li class="c6 c12 li-bullet-2"><span class="c2">Compliance with legal obligations.</span></li>
      </ul>
      <p class="c6"><span class="c2">For this purpose, we may process your Basic Personal Data, Identification and other background verification Data, Transaction Data, Information which is related to legal requirements, Contact Information and other Personal Data provided to us by or on behalf of you or generated by us in the course of providing services.</span>
      </p>
      <p class="c6"><span class="c2">Legal basis: fulfilling our or third parties&rsquo; legitimate interests and/or compliance with legal obligations applicable to us.</span>
      </p>
      <ul class="c7 lst-kix_list_4-0">
        <li class="c6 c12 li-bullet-1"><span class="c2">To provide an answer when you contact us through our website or other communication measures</span>
        </li>
      </ul>
      <p class="c6"><span class="c2">For this purpose, we may process your Basic Personal Data, Contact Information and other Personal Data provided to us by or on behalf of you.</span>
      </p>
      <p class="c6"><span
          class="c2">Legal basis: your consent, fulfilling our or third parties&rsquo; legitimate interests.</span></p>
      <p class="c0"><span class="c2">Under Legitimate Interest we mean the interest of ours as a business in conducting and managing our services to enable us to provide to you and offer the most secure experience.</span>
      </p>
      <p class="c0"><span class="c2">Under Legal Obligation we mean processing your Personal Data where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</span>
      </p>
      <p class="c0"><span class="c3">How long do we keep your information</span></p>
      <p class="c0"><span class="c2">We will keep customer information for the period when the customer has a business relation with us, and a further five years, and such further period during which you may bring a claim against us and for us to be able to defend ourselves. Where you apply for a business relation but we do not proceed, we will store your information for a reasonable period in case you make a repeat application.</span>
      </p>
      <p class="c0"><span class="c2">We will keep information about visitors to our websites (not being customers) for a reasonable length of time that lets us understand how people use our website and any technical issues they have. Usually this will not exceed 12 months.</span>
      </p>
      <p class="c0"><span class="c2">We will keep information about individuals who are our contacts at our business customer and suppliers for the duration of our relationship with the relevant business and where we are not made aware that the individual no longer works for it. We may keep our correspondence with these individuals for longer where relevant to our transactions with the business.</span>
      </p>
      <p class="c0"><span class="c2">For deleting your personal data please contact support.</span></p>
      <p class="c0"><span class="c3">Information we share with employees and partners</span></p>
      <p class="c0 c4"><span class="c2"></span></p>
      <p class="c0"><span class="c2">There are certain circumstances where we may transfer your personal data to our employees, contractors and to other parties, as selected according to different case.</span>
      </p>
      <p class="c0"><span class="c2">We may transfer your personal information to potential buyers of our business, contractors, and to our professional advisers (such as lawyers, accountants, auditors, IT consultants, management consultants).</span>
      </p>
      <p class="c0"><span class="c2">Your personal information may be transferred to other third party organizations if we&#39;re required to by law, or under any regulatory code or practice we follow, or if we are asked by any public or regulatory authority.</span>
      </p>
      <p class="c0"><span class="c3">Where your information will be held</span></p>
      <p class="c0"><span class="c2">We ensure the implementation of appropriate technical, organizational and administrative security measures required to ensure the security of your Personal Data processing, in order to protect your Personal Data from loss, misuse, accidental or unlawful destruction, modification, disclosure, unauthorized access or any other unlawful handling.</span>
      </p>
      <p class="c0"><span class="c2">Third-party service providers that may engage in the processing of Personal Data on our behalf (for the purposes indicated above) are also contractually obligated to respect the confidentiality of the Personal Data.</span>
      </p>
      <p class="c0"><span class="c3">Using cookies</span></p>
      <p class="c0"><span class="c2">If you access our information or services through the AIaro website, you should be aware that cookies are used. Cookies are data files stored on your browser. If you have accepted cookies by using tools displayed when entering the AIaro website, the website automatically installs and uses them on your browser when you access it. For more information on how to control your Cookie settings and browser settings or how to delete Cookies on your hard drive, please read the Cookies Policy.</span>
      </p>
      <p class="c0"><span class="c3">Your Rights</span></p>
      <p class="c0"><span class="c2">You have certain rights in relation to your information. Under certain circumstances and in accordance with applicable data protection laws, you may have the right:</span>
      </p>
      <ul class="c7 lst-kix_list_4-0">
        <li class="c10 li-bullet-1"><span class="c2">to be told how we use your information and obtain access to your information;</span>
        </li>
        <li class="c10 li-bullet-2"><span class="c2">to have your information rectified or erased or place restrictions on processing your information;</span>
        </li>
        <li class="c10 li-bullet-1"><span class="c2">to object to the processing of your information e.g. for direct marketing purposes or where the processing is based on our legitimate interests;</span>
        </li>
        <li class="c10 li-bullet-2"><span class="c2">to have any information you provided to us on an automated basis returned to you in a structured, commonly used and machine-readable format, or sent directly to another company, where technically feasible (&ldquo;data portability&rdquo;);</span>
        </li>
        <li class="c10 li-bullet-1"><span class="c2">where the processing of your information is based on your consent, to withdraw that consent subject to legal or contractual restrictions;</span>
        </li>
        <li class="c10 li-bullet-2"><span class="c2">to object to any decisions based on the automated processing of your personal data, including profiling; and</span>
        </li>
        <li class="c0 c11 li-bullet-2"><span class="c2">to lodge a complaint with the supervisory authority responsible for data protection matters.</span>
        </li>
      </ul>
      <p class="c0"><span class="c2">If we hold any information about you which is incorrect or if there are any changes to your details, please let us know by so that we can keep our records accurate and up to date.</span>
      </p>
      <p class="c0 c4"><span class="c2"></span></p>
      <p class="c0"><span class="c8">If you withdraw your consent to the use of your personal information for purposes set out in our Privacy Notice, we may not be able to provide you with access to all or parts of our website, applications, and services.</span><span
          class="c3">&nbsp;</span></p>
      <p class="c0"><span class="c3">Links to third party website</span></p>
      <p class="c0"><span class="c2">Our website, newsletters, email updates and other communications, from time to time, may contain links to and from the websites of others including our partner networks, advertisers and other group companies and/or social networks as offered to you and supported by your browser.</span>
      </p>
      <p class="c0"><span class="c2">The personal data that you provide through these websites is not subject to this Privacy Policy and the treatment of your personal data by such websites is not our responsibility. If you follow a link to any of these websites, please note that these websites have their own privacy notices which will set out how your information is collected and processed when visiting those sites. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy notices of entities through which you chose to share.</span>
      </p>
      <p class="c0"><span class="c3">Automated decision making</span></p>
      <p class="c0"><span class="c2">We may use some instances of your data in order customize our Services and the information we provide to you, and to address your needs - such as your country of address and transaction history. For example, if you frequently send funds from one particular currency to another, we may use this information to inform you of new product updates or features that may be useful for you. When we do this, we take all necessary measures to ensure that your privacy and security are protected - and we only use pseudonymized data where ever possible.</span>
      </p>
      <p class="c0"><span class="c3">Changes to the Privacy Policy</span></p>
      <p class="c0"><span class="c2">We may change this Privacy Policy from time to time. However, we will not reduce your rights under this Privacy Policy. We will always update this Privacy Policy on our website, so please try to read it when you visit the website (the &lsquo;last updated&rsquo; reference tells you when we last updated this Privacy Policy).</span>
      </p>
      <p class="c0"><span class="c3">Further information</span></p>
      <p class="c13"><span class="c8">If you have any questions, concerns or complaints regarding our compliance with this Privacy Policy and the data protection laws, or if you wish to exercise your rights, we encourage you to first contact us using the contact details below. We will investigate and attempt to resolve complaints and disputes and will make every reasonable effort to honour your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by data protection laws. Any questions, comments and requests regarding this Privacy Policy should be addressed by writing to us at: Heelsumstraat 51, E-Commerce Park, Curacao, Willemstad, &nbsp;PO Box 422, ZIP Code 4797 or by sending an e-mail to: </span><span
          class="c8 c9"><a class="c14" href="mailto:info@comtechnv.com">info@comtechnv.com</a></span><span class="c2">&nbsp;</span>
      </p>
      <p class="c0 c4"><span class="c2"></span></p>

    </div>

  </section>
</template>

<script>

</script>


<style scoped lang="stylus">

.terms
  position relative
  top 0
  left 0
  width 100%
  height auto
  z-index: 5;
  background: radial-gradient(50% 96% at 50% 0%, #532C91 0%, #27144E 100%);
  text-decoration none
  cursor auto

.terms_container
  position relative
  top: 160px;
  left 50%
  transform translate(-50%)
  width 1160px
  margin-bottom: 250px;


  .lst-kix_list_2-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_2-7 > li:before {
    content: "o  "
  }

  ul.lst-kix_list_1-0 {
    list-style-type: none
  }

  .lst-kix_list_2-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_2-5 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_2-8 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_3-0 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_3-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_3-2 > li:before {
    content: "\0025aa  "
  }

  ul.lst-kix_list_3-7 {
    list-style-type: none
  }

  ul.lst-kix_list_3-8 {
    list-style-type: none
  }

  ul.lst-kix_list_1-3 {
    list-style-type: none
  }

  ul.lst-kix_list_3-1 {
    list-style-type: none
  }

  .lst-kix_list_3-5 > li:before {
    content: "\0025aa  "
  }

  ul.lst-kix_list_1-4 {
    list-style-type: none
  }

  ul.lst-kix_list_3-2 {
    list-style-type: none
  }

  ul.lst-kix_list_1-1 {
    list-style-type: none
  }

  .lst-kix_list_3-4 > li:before {
    content: "o  "
  }

  ul.lst-kix_list_1-2 {
    list-style-type: none
  }

  ul.lst-kix_list_3-0 {
    list-style-type: none
  }

  ul.lst-kix_list_1-7 {
    list-style-type: none
  }

  .lst-kix_list_3-3 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_3-5 {
    list-style-type: none
  }

  ul.lst-kix_list_1-8 {
    list-style-type: none
  }

  ul.lst-kix_list_3-6 {
    list-style-type: none
  }

  ul.lst-kix_list_1-5 {
    list-style-type: none
  }

  ul.lst-kix_list_3-3 {
    list-style-type: none
  }

  ul.lst-kix_list_1-6 {
    list-style-type: none
  }

  ul.lst-kix_list_3-4 {
    list-style-type: none
  }

  .lst-kix_list_3-8 > li:before {
    content: "\0025aa  "
  }

  li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  .lst-kix_list_4-0 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_4-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_3-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_3-7 > li:before {
    content: "o  "
  }

  .lst-kix_list_4-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_4-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_4-5 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_4-2 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_4-6 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_4-8 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_4-7 > li:before {
    content: "o  "
  }

  ul.lst-kix_list_4-8 {
    list-style-type: none
  }

  ul.lst-kix_list_4-6 {
    list-style-type: none
  }

  ul.lst-kix_list_2-8 {
    list-style-type: none
  }

  ul.lst-kix_list_4-7 {
    list-style-type: none
  }

  ul.lst-kix_list_4-0 {
    list-style-type: none
  }

  ul.lst-kix_list_2-2 {
    list-style-type: none
  }

  ul.lst-kix_list_4-1 {
    list-style-type: none
  }

  .lst-kix_list_1-0 > li:before {
    content: "\0025cf  "
  }

  ul.lst-kix_list_2-3 {
    list-style-type: none
  }

  ul.lst-kix_list_2-0 {
    list-style-type: none
  }

  ul.lst-kix_list_2-1 {
    list-style-type: none
  }

  ul.lst-kix_list_4-4 {
    list-style-type: none
  }

  ul.lst-kix_list_2-6 {
    list-style-type: none
  }

  ul.lst-kix_list_4-5 {
    list-style-type: none
  }

  .lst-kix_list_1-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_1-2 > li:before {
    content: "\0025aa  "
  }

  ul.lst-kix_list_2-7 {
    list-style-type: none
  }

  ul.lst-kix_list_4-2 {
    list-style-type: none
  }

  ul.lst-kix_list_2-4 {
    list-style-type: none
  }

  ul.lst-kix_list_4-3 {
    list-style-type: none
  }

  ul.lst-kix_list_2-5 {
    list-style-type: none
  }

  .lst-kix_list_1-3 > li:before {
    content: "\0025cf  "
  }

  .lst-kix_list_1-4 > li:before {
    content: "o  "
  }

  .lst-kix_list_1-7 > li:before {
    content: "o  "
  }

  .lst-kix_list_1-5 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_1-6 > li:before {
    content: "\0025cf  "
  }

  li.li-bullet-0:before {
    margin-left: -17.9pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 17.9pt
  }

  li.li-bullet-2:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
  }

  .lst-kix_list_2-0 > li:before {
    content: "-  "
  }

  .lst-kix_list_2-1 > li:before {
    content: "o  "
  }

  .lst-kix_list_1-8 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_2-2 > li:before {
    content: "\0025aa  "
  }

  .lst-kix_list_2-3 > li:before {
    content: "\0025cf  "
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
        padding: 0
    }

  .c10 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c5 {
    margin-left: 35.9pt;
    padding-top: 0pt;
    padding-left: -0.1pt;
    padding-bottom: 6pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c6 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 6pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c2 {
    color: #f8f2ff;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Sora', sans-serif;
    font-style: normal
  }

  .c3 {
    color: #f8f2ff;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: 'Sora', sans-serif;
    font-style: normal
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c0 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c13 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c15 {
    background-color: #ffffff;
    max-width: 481.9pt;
    padding: 42.5pt 42.5pt 42.5pt 70.8pt
  }

  .c9 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    text-decoration-skip-ink: none
  }

  .c8 {
    font-family: 'Sora', sans-serif;
    color: #f8f2ff;
    font-weight: 400
  }

  .c7 {
    padding: 0;
    margin: 0
  }

  .c11 {
    margin-left: 36pt;
    padding-left: 0pt
  }

  .c14 {
    color: inherit;
    text-decoration: inherit
  }

  .c12 {
    padding-left: 0pt
  }

  .c4 {
    height: 11pt
  }

  .title {
    padding-top: 24pt;
    color: #F8F2FF;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #F8F2FF;
    font-size: 11pt;
    font-family: 'Sora', sans-serif;
  }

  p {
    margin: 0;
    color: #F8F2FF;
    font-size: 11pt;
    font-family: 'Sora', sans-serif;
  }

  h1 {
    padding-top: 24pt;
    color: #F8F2FF;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #F8F2FF;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #F8F2FF;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 12pt;
    color: #F8F2FF;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #F8F2FF;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #F8F2FF;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

@media (max-width: 1175px)
  .terms_container {
    width 85%
  }
</style>
