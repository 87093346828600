<template>

  <section class="footer">
    <div class="footer_container">
      <v-lazy-image src="img/footer_img.png" class="footer_img" alt=""/>
      <div class="footer_row">
        <div class="footer_headline">Want to discuss your project?</div>
        <div class="footer_subtext">Email us:</div>
        <div @click="mail()" class="footer_text_itself">info@comtechnv.com</div>
        <v-lazy-image @click="mail()" src="img/footer_button.svg" class="footer_button" alt=""/>
      </div>
      <div class="footer_legal">
        <div class="privacy" @click="privacy()" id="privacy">Privacy Policy</div>
        <div class="terms" @click="terms()" id="terms">Terms And Conditions</div>
        <div class="copyright">© 2023 Complete Tehnologies. All rights reserved</div>
      </div>
    </div>

  </section>
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage
  },
  methods: {
    privacy() {
      document.getElementById("privacy_all").style.display = "block";
      document.getElementById("main_page").style.display = "none";
      document.getElementById("terms_all").style.display = "none";
      document.getElementById("privacy").style.opacity = "0";
      document.getElementById("terms").style.opacity = "1";
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    terms() {
      document.getElementById("terms_all").style.display = "block";
      document.getElementById("main_page").style.display = "none";
      document.getElementById("privacy_all").style.display = "none";
      document.getElementById("terms").style.opacity = "0";
      document.getElementById("privacy").style.opacity = "1";
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    mail() {
      window.location.href = "mailto:info@comtechnv.com";
    }
  }
}
</script>


<style lang="stylus">

.logo_img
  position relative
  width: 184.78px;
  height: 63px;


.footer
  position relative
  top 0
  left 0
  width 100%
  height 480px
  z-index: 5;
  background: radial-gradient(50% 96% at 50% 0%, #532C91 0%, #27144E 100%);
  overflow: hidden;

.footer_container
  position relative
  top 0
  left 50%
  transform translate(-50%)
  width 1160px
  height 70px
  display flex
  justify-content: space-between;

.footer_img
  position: absolute;
  width: 107px;
  height: 107px;
  left: 0
  top: 69px;

.footer_row
  position: relative;
  top: 52px;
  left: 166px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

.footer_headline
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 150%;
  color: #F8F2FF;

.footer_subtext
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 150%;
  color: #F8F2FF;
  top: 8px;
  position: relative;

.footer_text_itself
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #ED0063;
  position relative
  top: 8px;
  cursor pointer
  transition filter .5s linear

.footer_button
  position relative
  top 54px
  transition filter .5s linear
  cursor pointer
  z-index 6

.footer_button:hover, .footer_text_itself:hover
  filter brightness(1.5)

.footer_legal
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 362px;

.privacy, .terms
  position relative
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #F8F2FF;
  cursor pointer
  transition color .5s ease-in-out

.privacy:hover, .terms:hover
  color #ED0063

.copyright
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #CCC3D9;

@media (max-width: 1175px)
  .footer_headline
    display none
  .footer_container
    width 320px
  .footer_img {
    width: auto;
    height: 78px;
    left: -2px;
    top: 92px;
  }
  .footer_button
    top: 262px;
    left: -170px;
  .footer_subtext
    font-size 16px
    position absolute
    top: 139px;
    left: -168px;
  .footer_text_itself
    font-size 16px
    color: #f8f2ff;
    position absolute
    top: 164px;
    left: -168px;
  .footer_legal {
      width: 100%;
      display: flex;
      justify-content: space-between;
      top: 391px;
      flex-wrap: wrap;
      height: 100px;
    }
  .copyright
    font-size 12px



</style>
