<template>

  <ref_img style="display: none"/>
  <header_all/>
  <main_page id="main_page"/>
  <terms_all id="terms_all" style="display: none"/>
  <privacy_all id="privacy_all" style="display: none"/>
  <footer_all/>


</template>

<script>
import main_page from './components/main_page';
import ref_img from './components/ref_img';
import header_all from "@/components/header_all";
import footer_all from "@/components/footer_all";
import terms_all from "@/components/terms_all";
import privacy_all from "@/components/privacy_all";

export default {
  name: 'App',
  components: {
    ref_img,
    header_all,
    main_page,
    terms_all,
    privacy_all,
    footer_all,

  },
  methods: {

  }
}

</script>

<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  position absolute
  top 0
  margin-top 0
  overflow-y: hidden;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline
}

body {
  line-height: 1;
  color: #222;
  background: #26134D
}

ol, ul {
  list-style: none
}

table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle
}

a img {
  border: none
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.v-lazy-image
  opacity 0
  margin-top 20px
  transition all 0.3s ease-in-out


.v-lazy-image-loaded
  opacity 1
  margin-top 0

</style>
