<template>

  <img src="img/ref.png" class="ref_img" alt="">

</template>

<script>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ref_img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 0;
}
</style>
