<template>

  <section class="terms">
    <div class="terms_container">
      <p class="c8"><span class="c3">TERMS AND CONDITIONS</span></p>
      <p class="c2"><span class="c0">These Terms and Conditions (&quot;Agreement&quot; or &ldquo;Terms&rdquo;) is an agreement between you and Complete Technologies N.V. and applies to your use of the website at &nbsp;http://comtechnv.com/ &nbsp;(referred to herein below as the &ldquo;website&rdquo;).</span>
      </p>
      <p class="c2"><span class="c0">Please read carefully, agree and accept all the terms and conditions contained in this Agreement and the Privacy Policy and other Policies contained on the website, which are an integral part of this Agreement, before you become a member of Complete Technologies N.V.</span>
      </p>
      <p class="c2"><span class="c0">These Terms apply in full force and effect to your use of this website and by using this website, you expressly accept all terms and conditions contained herein in full. You must not use this website, if you have any objection to any of these Terms.</span>
      </p>
      <p class="c2"><span class="c0">You understand and agree that your access to and use of the website is entirely at your own discretion and risk, and that you will be solely responsible for any loss or damage that may arise from such access and use. </span>
      </p>
      <p class="c2"><span class="c0">Please note that any actions on the website that are made by using your Internet connection or electronic device, unless you prove otherwise, shall be deemed as have been taken by you.</span>
      </p>
      <p class="c2"><span class="c0">The following definitions will apply here:</span></p>
      <p class="c2"><span class="c0">&quot;you&quot; or &quot;your&quot; means any natural person or entity using the website (&quot;Client&quot;), unless otherwise stated herein. &quot;Complete Technologies&quot;, &quot;we&quot;, &quot;our&quot;, &quot;Partners&quot; or &quot;our&quot; together will refer to Complete Technologies N.V.and its subsidiaries, Partners, unless otherwise stated.</span>
      </p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c2"><span class="c3">Information about us</span></p>
      <p class="c4"><span class="c0">This website is owned and maintained by Complete Technologies N.V., a company incorporated under the laws of Curacao, with the registered office at: Heelsumstraat 51, E-Commerce Park, Curacao, Willemstad, &nbsp;PO Box 422, ZIP Code 4797, registration number 133283.</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c3">Conditions of Use</span></p>
      <p class="c2"><span class="c0">You are specifically restricted from using this website in any way that is or may be damaging to this website, using this website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity.</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">You may use the Website only for lawful purposes and in accordance with these Terms. You agree not to use the Website:</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">In any way that violates any applicable federal, state, local or international law or regulation;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">In any manner that involves transmitting or sending of, any advertising or promotional material, including any &ldquo;junk mail&rdquo;, &ldquo;chain letter&rdquo; or &ldquo;spam&rdquo; or any other similar solicitation;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">In any manner that involves impersonating or attempt to impersonate the Company, a Company`s employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing);</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">In any manner that involves engaging in any other conduct that restricts or inhibits anyone&rsquo;s use or enjoyment of the Website or our digital assets, or which, as determined by us, may harm the Company or users of the Website or expose them to liability;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">In the manner that is or may be considered as defamatory, harassing, pornographic, embarrassing, vulgar, malicious, harmful, threatening, indecent, derogatory, discriminatory, untrue, political, abusive, sexist, hateful, offensive, menacing, obscene, racist, profane.</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">In addition, you agree not to:</span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Use any robot, spider or another automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party&rsquo;s use of the Website, including their ability to engage in real time activities through the Website;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Use any device, software or routine that interferes with the proper working of the Website;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Website, the server on which the Website is stored, or any server, computer or database connected to the Website;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Attack the Website via a denial-of-service attack or a distributed denial-of-service attack;</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c0">Otherwise attempt to interfere with the proper working of the Website.</span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c3">Intellectual Property</span></p>
      <p class="c2"><span class="c0">Complete Technologies own all rights to the intellectual property and material contained in this website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this website. You must not republish material from this website (including republication on another website), or reproduce or store material from this website in any public or private electronic retrieval system; you must not reproduce, duplicate, copy, sell, re-sell, visit, or otherwise exploit our website or material on our website for a commercial purpose, without our express written consent. Elements of the website are protected by trade dress, trade secret and other laws and may not be copied or imitated in whole or in part. All custom graphics, icons, and other items that appear on the website are trademarks, service marks or trade dress of Complete Technologies and may not be used or interfered with in any manner without the express written consent of Complete Technologies. In consideration of this, users agree that copies of the information will retain all copyright and other proprietary notices.</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c3">Legal terms</span></p>
      <p class="c2"><span class="c0">This website may contain other proprietary notices and copyright information, the terms of which must be observed and followed. Information on this website may contain typographical errors or technical inaccuracies. Information may be changed or updated without notice. </span>
      </p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c2"><span class="c3">Indemnification and Limitation of liability </span></p>
      <p class="c2"><span class="c0">You hereby indemnify to the fullest extent Complete Technologies from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</span>
      </p>
      <p class="c2"><span class="c0">IN NO EVENT WILL Complete Technologies BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY USE OF THIS WEBSITE, OR ON ANY OTHER HYPER LINKED WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE, EVEN IF WE ARE EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. Complete Technologies IS NOT RESPONSIBLE FOR CONTENT OF THIRD-PARTIES WEBSITES.</span>
      </p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c2"><span class="c3">Modifications</span></p>
      <p class="c2"><span class="c0">We can revise and update these Terms at any time without notice. In case of any changes, amendments, modifications, or corrections, we will post the revised version of the Terms on the website. It is your responsibility to check these Terms for any changes whenever you access the website. Your continued usage of the website after any changes to these Terms of Use will mean you accept those changes</span>
      </p>
      <p class="c4 c5"><span class="c3"></span></p>
      <p class="c4"><span class="c3">Contact us</span></p>
      <p class="c4"><span class="c7">Any questions, comments and requests regarding these Terms should be addressed by writing to us at: Heelsumstraat 51, E-Commerce Park, Curacao, Willemstad, &nbsp;PO Box 422, ZIP Code 4797, or by sending an e-mail to: </span><span
          class="c6"><a class="c10" href="mailto:info@comtechnv.com">info@comtechnv.com</a></span><span
          class="c0">&nbsp;</span></p>

    </div>

  </section>
</template>

<script>

</script>


<style scoped lang="stylus">

.terms
  position relative
  top 0
  left 0
  width 100%
  height auto
  z-index: 5;
  background: radial-gradient(50% 96% at 50% 0%, #532C91 0%, #27144E 100%);
  text-decoration none
  cursor auto

.terms_container
  position relative
  top: 160px;
  left 50%
  transform translate(-50%)
  width 1160px
  margin-bottom: 250px;


  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
        padding: 0
    }

  .c0 {
    color: #f8f2ff;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Sora', sans-serif;
    font-style: normal
  }

  .c3 {
    color: #f8f2ff;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: 'Sora', sans-serif;
    font-style: normal
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
  }

  .c2 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: justify
  }

  .c6 {
    -webkit-text-decoration-skip: none;
    color: #f8f2ff;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-family: 'Sora', sans-serif;
  }

  .c8 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c4 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 150%;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c9 {
    background-color: #ffffff;
    max-width: 481.9pt;
    padding: 42.5pt 42.5pt 42.5pt 70.8pt
  }

  .c7 {
    font-family: 'Sora', sans-serif;
    color: #f8f2ff;
    font-weight: 400
  }

  .c10 {
    color: inherit;
    text-decoration: inherit
  }

  .c5 {
    height: 11pt
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Sora', sans-serif;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Sora', sans-serif;
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: 'Sora', sans-serif;
    line-height: 150%;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

@media (max-width: 1175px)
  .terms_container
    width 85%

</style>
