<template>

  <section class="header" id="header">
  <v-lazy-image src="img/logo.svg" @click="main_page()" class="logo_img" alt=""/>
    <img src="img/burger.svg" id="burger" @click="burger()" class="burger" alt=""/>
    <div class="menu_bg" id="menu_bg"></div>
    <div class="header_menu" id="header_menu">
      <v-lazy-image src="img/cross.svg" @click="cross()" class="cross" alt=""/>
      <a href="#about_us" @click="main_page()" class="header_item">About Us</a>
      <a href="#our_main_project" @click="main_page()" class="header_item">Our Main Project</a>
      <a href="#our_services" @click="main_page()" class="header_item">Our Services</a>
      <a href="#career" @click="main_page()" class="header_item">Career</a>

    </div>
  </section>
</template>

<script>
import VLazyImage from "v-lazy-image";

export default {
  components: {
    VLazyImage
  },
  methods: {
    main_page() {
      document.getElementById("terms_all").style.display = "none";
      document.getElementById("main_page").style.display = "block";
      document.getElementById("privacy_all").style.display = "none";
      document.getElementById("terms").style.opacity = "1";
      document.getElementById("privacy").style.opacity = "1";
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    burger() {
      document.getElementById("burger").style.display = "none";
      document.getElementById("menu_bg").style.display = "block";
      document.getElementById("header_menu").style.display = "flex";
    },
    cross() {
      document.getElementById("burger").style.display = "block";
      document.getElementById("menu_bg").style.display = "none";
      document.getElementById("header_menu").style.display = "none";
    }

  }
}
</script>


<style lang="stylus">

.logo_img
  position relative
  width: 184.78px;
  height: 63px;
  cursor pointer
  transition filter .5s linear

.logo_img:hover
  filter brightness(1.5)


.header
  position absolute
  top 0
  left 50%
  transform translate(-50%)
  width 1160px
  height 70px
  display flex
  justify-content: space-between;
  margin-top: 36px;
  z-index: 6;

.header_menu
  position relative
  display flex
  justify-content: space-between;
  width: 495px;
  margin-top: -7px;

.header_item
  position relative
  font-family: 'Sora', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #F8F2FF;
  cursor pointer
  transition color .5s ease-in-out

.header_item:hover
  color #ED0063

a
  text-decoration none

.cross
  display none
.menu_bg
  display none
.burger
  display none

@media (max-width: 1175px)
  .header
    width 360px
    margin-top: 12px;
  .header_menu {
    display none
    justify-content: space-between;
    width: auto;
    margin-top: 68px;
    flex-direction: column;
    height: 205px;
    margin-right: 51px;
  }
  .logo_img {
    width: auto!important
    height: 44px!important
    margin-left: 15px!important
  }
  .header_item {
    text-decoration none
    font-size: 14px;
  }
  .cross
    display block
    position absolute
    cursor pointer
    top: -58px;
    left: 132px;
  .menu_bg
    display: none;
    position: absolute;
    top: -15px;
    right: 0;
    z-index: 0;
    width: 203px;
    height: 332px;
    background: #351c64;
  .burger
    display: block;
    height: 24px;
    position: relative;
    top: 10px;
    left: -16px;
    cursor pointer




</style>
